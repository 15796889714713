.error-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.error-title {
    font-size: 200px;
    text-align: center;
    font-weight: bold;
    color: var(--accent4);
    text-decoration: none;
}

.error-subtitle {
    font-size: 50px;
    text-align: center;
    font-weight: bold;
    color: var(--text-color);
    text-decoration: none;
    margin-top: -200px;
}

.error-button {
    margin: auto;
    margin-top: -20px;
    width: 30%;
    min-width: 400px;
    height: 50px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    border-radius: 5px;
    background-color: var(--accent4);
    text-decoration: none;
    color: var(--text-color);
    font-size: 20px;
    font-weight: bold;
    border: none;
    cursor: pointer;
}