:root {
    --selected: #fff;
    --unselected: #fff;
}

.left-nav {
    display: flex;
    align-items: center;
    height: 100%;
}

.nav-options {
    margin: 0;
    padding: 30px;
    display: flex;
    list-style: none;
    height: 100%;
    align-items: center;
}

.nav-option {
    text-align: center;
}


.nav-text {
    text-decoration: none;
    padding: 10px;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
    margin-right: 2vw;
    font-style: italic;
}

.nav-text:hover {
    background-color: rgba(0,0,0,0.2);
    border-radius: 5px;
    transition: 0.4s;
}


  
.selected {
    color: var(--accent4);
    text-decoration: none;
}

.unselected {
    color: var(--unselected);   
    text-decoration: none;
}
  

  
.heading {
    width: 100%;
    margin: 0 auto;
    height: var(--nav-height);
    padding: 20px 20px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
  
.heading img {
    height: 75px;
    width: 75px;
    margin-right: 20px;
    border-radius: 4px;
}
  
.title {
    text-decoration: none;
    font-size: 35px;
    font-weight: bold;
    font-style: italic;
    color: var(--text-color);
}


/* when window width < 500px switch */
@media only screen and (max-width:1120px) {

    .heading img {
        height: 200px !important;
        width: 200px !important;
    }

    .heading {
        height: var(--nav-height-mobile);
    }

    .nav-options {
        display: block;
        margin: auto 0;
    }

    .nav-option {
        padding: 0;
        margin-bottom: 10px;
        margin-right: 0 !important;
    }
    .nav-text {
        text-align: center;
        padding: 6px;
    }



}

/* when window width < 500px switch */
@media only screen and (max-width: 600px) {
    .title {
        display: none;
    }

    .heading {
        height: var(--nav-height-mobile);
    }

    .heading img {
        height: 200px !important;
        width: 200px !important;
    }

    .nav-options {
        display: block;
        margin: auto 0;
    }

    .nav-option {
        margin-right: 0 !important;
        margin-bottom: 10px;
    }

    .nav-text {
        padding: 6px;
        font-size: 24px;
    }

}