.confirm-container {
    width: 100%;
    margin: auto;
}

.confirm-logo-a {
    text-decoration: none;
}

.confirm-title-a {
    text-decoration: none;
}

.confirm-heading {
    width: 100%;
    margin: auto;
    height: 100px;
    padding: 5px 20px;
    display: flex;
    align-items: center;
}

.confirm-title {
    font-size: 30px;
    font-weight: bold;
    color: var(--text-color);
    text-decoration: none;
}

.confirm-logo {
    height: 50px;
    width: 50px;
    margin-right: 20px;
    filter: sepia(25%);
    -webkit-filter: sepia(25%);
}
.confirm-content {
    padding: 25px;
    font-size: 24px;
    font-weight: bold;
}