:root {
  --background: #3d3d3d;
  --nav-height: 100px;
  --nav-height-mobile: 200px;
  --main: #4F92FF;
  --accent1: #20E6FA;
  --accent2: #FADE20;
  --accent3: #FAB320;
  --accent4: #4C6EA5;
  --text-color: #fff;
}

html, body {
  height: 100%;
  width: 100%;
  background: radial-gradient(circle, var(--main) 0%, var(--accent1) 100%);
  font-family: 'Montserrat', sans-serif;
  color: var(--text-color);
  overflow-x: hidden;
}



.container {
  overflow-x: hidden;
}

.home-content {
  padding: 10px 8%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.home-content p {
  font-weight: bold;
  font-size: 50px;
  color: white;
}
