:root {
    --background: #3c3e5b;
    --accent: #fff;
    --text-color: #fff;
    --success: #69e67e;
    --error: var(--accent4);
    --error-text: rgb(205, 10, 10);
}

.title {
    font-size: 30px;
}

.reset-password-container-error {
    width: 414px;
    margin: 20px auto;
    text-align: center;
    color: var(--text-color);
    padding: 10px 0;
    /* border: solid 2px var(--error); */
    border-radius: 5px;
}

.reset-password-container-success {
    width: 414px;
    margin: 20px auto;
    text-align: center;
    color: var(--text-color);
    /* border: solid 4px var(--success); */
    border-radius: 5px;
}



.form-group {
    width: 100%;
    margin: 20px 0px;

}

.label {
    float: left;
    font-weight: bold;
    width: 100%;
    font-size: 25px;
    margin-bottom: 10px;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.input-error {
    background-color: rgba(0, 0, 0, 0) !important;
    color: var(--text-color);
    width: 80%;
    height: 30px;
    padding: 8px;
    font-size: 20px;
    border-radius: 7px;
    font-weight: bold;
    border: var(--error) 3px solid;
}

.input-success {
    background-color: rgba(0, 0, 0, 0) !important;
    color: var(--text-color);
    width: 80%;
    height: 30px;
    padding: 8px;
    font-size: 20px;
    border-radius: 7px;
    font-weight: bold;
    border: var(--success) 3px solid; 
}

.submit-button {
    width: 84%;
    height: 50px;
    border-radius: 5px;
    color: var(--text-color);
    border: #000000 2px solid;
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
    cursor: pointer;
    margin-bottom: 20px;
    background: linear-gradient(to left, var(--error) 50%, var(--success) 50%) right;
    background-size: 200% 100%;
    transition: .3s ease-out;
}

.submit-button:hover {
    /* transition color change from left to right */
    background-position: left;
    color: var(--text-color);
    border: var(--accent) 2px solid;

}

.error {
    margin-left: 9%;
    float: left;
    font-weight: bold;
    color: var(--error-text);
    font-size: 20px;
}